/*
Flaticon icon font: Flaticon
*/
@font-face {
	font-family: "Flaticon";
	src: url("../../assets/fonts/Flaticon.eot");
	src: url("../../assets/fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
	url("../../assets/fonts/Flaticon.woff2") format("woff2"),
	url("../../assets/fonts/Flaticon.woff") format("woff"),
	url("../../assets/fonts/Flaticon.ttf") format("truetype"),
	url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
	font-weight: normal;
	font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: "Flaticon";
		src: url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
	}
}
[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
	font-family: Flaticon;
	font-style: normal;
}

.flaticon-graph:before { content: "\f100"; }
.flaticon-medal:before { content: "\f101"; }
.flaticon-team:before { content: "\f102"; }
.flaticon-briefcase:before { content: "\f103"; }
.flaticon-play-button:before { content: "\f104"; }
.flaticon-balance:before { content: "\f105"; }
.flaticon-sort:before { content: "\f106"; }
.flaticon-analytics:before { content: "\f107"; }
.flaticon-chess-pieces:before { content: "\f108"; }
.flaticon-people:before { content: "\f109"; }
.flaticon-briefcase-1:before { content: "\f10a"; }
.flaticon-quote:before { content: "\f10b"; }
.flaticon-menu:before { content: "\f10c"; }
.flaticon-double-right-arrows-angles:before { content: "\f10d"; }
.flaticon-settings:before { content: "\f10e"; }
.flaticon-bank:before { content: "\f10f"; }
.flaticon-home:before { content: "\f10f"; }
.flaticon-magnifying-glass:before { content: "\f110"; }
